import React from "react"
import { Section, Container, Form } from "../components/utils"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import styled from "styled-components"

const ContactPage = ({
  data: {
    page: { intro, image, contactOptions, socialMedia, openingTimes },
  },
  location,
}) => {
  let params = new URLSearchParams(location.search)
  const selectedOption = params.get("selected")
  const success = params.get("success")
  return (
    <Layout>
      <SEO title="Contact Us" />
      <Section padding="medium">
        <ContactSection>
          {success === "true" ? (
            <div>
              <h1>Thanks for gettng in touch!</h1>
              <p>
                We appreciate your interest. A member of our team will be
                getting back to you shortly.
              </p>
            </div>
          ) : (
            <div>
              <div dangerouslySetInnerHTML={{ __html: intro }} />
              <Form name="Contact" selected={selectedOption} />
            </div>
          )}

          <Img fluid={image.fluid} />
          <div>
            <h2 className="accent">Or give us a call</h2>
            {contactOptions.map(({ text, link, icon }) => {
              if (link) {
                return (
                  <a href={link} key={text}>
                    <IconRow>
                      <Img className="icon" fixed={icon.fixed} />
                      <h3>{text}</h3>
                    </IconRow>
                  </a>
                )
              } else {
                return (
                  <IconRow key={text}>
                    <Img className="icon" fixed={icon.fixed} />
                    <h3>{text}</h3>
                  </IconRow>
                )
              }
            })}
            <h2 className="accent">Find us on social media</h2>
            {socialMedia.map(({ icon, link, name }) => (
              <IconRow key={name}>
                <Img className="icon" fixed={icon.fixed} />
                <a href={link}>
                  <h3>{name}</h3>
                </a>
              </IconRow>
            ))}
          </div>
          <div>
            <h2 className="accent">Opening Times</h2>
            {openingTimes.map(({ day, times }) => (
              <TimeRow key={day}>
                <div className="day">{day}:</div>
                <div>{times}</div>
              </TimeRow>
            ))}
          </div>
        </ContactSection>
      </Section>
    </Layout>
  )
}

export default ContactPage

const ContactSection = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
  h1 {
    margin-bottom: 0.7rem;
  }
  h2 {
    margin-bottom: 1rem;
  }
`
const IconRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  :last-of-type {
    margin-bottom: 1.5rem;
  }
  .icon {
    flex: 0 0 20px;
    margin-right: 15px;
  }
  h3 {
    margin: 0;
  }
`
const TimeRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  margin-bottom: 0.7rem;
  .day {
    font-weight: 600;
  }
`

export const query = graphql`
  {
    page: datoCmsContactPage {
      intro
      image {
        fluid(imgixParams: { fit: "crop", ar: "16:9" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      contactOptions {
        text
        link
        icon {
          fixed(width: 20) {
            ...GatsbyDatoCmsFixed
          }
        }
      }
      socialMedia {
        icon {
          fixed(width: 20) {
            ...GatsbyDatoCmsFixed
          }
        }
        link
        name
      }
      openingTimes {
        day
        times
      }
    }
  }
`
